// Sticky header on scroll
$(document).ready(() => {
	$(window).resize(() => {
		var navMaxHeight = $(window).height() - $('.navbar-header').height();
		$('.navbar-collapse').css({ maxHeight: `${navMaxHeight}px` });
	});
	$(window).load(() => {
		$('.sticky').sticky({ topSpacing: 0 });
	});
});

// WOW plugin triggers animate.css on scroll
$(document).ready((WOW) => {
	var wow = new WOW(
		{
			boxClass: 'wow', 			// animated element css class (default is wow)
			animateClass: 'animated', 	// animation css class (default is animated)
			offset: 100, 				// distance to the element when triggering the animation (default is 0)
			mobile: false        		// trigger animations on mobile devices (true is default)
		}
	);
	wow.init();
});

// Sales page scrolling
if (top.location.pathname == '/sales-tools.html' || top.location.pathname == '/sales-tools') { //Sales page only
	$(window).on('scroll', () => {
		var scrollPos = $(document).scrollTop();
		scrollPos = scrollPos + 200;
		$('.stt-subnavigation a').each(function () {
			var currLink = $(this);
			var refElement = $(currLink.attr('href'));
			if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
				$('.stt-subnavigation a').removeClass('active');
				currLink.addClass('active');
			}
			else {
				currLink.removeClass('active');
			}
		});
	});
	$('.scrollTo').on('click', function (e) {
		e.preventDefault();
		var target = $(this).attr('href');
		$('html, body').animate({
			scrollTop: ($(target).offset().top - 200)
		}, 200);
	});
	$(window).on('scroll', () => {
		var scrollTop = $(window).scrollTop(),
			elementOffset = $('.stt-subnavigation-top').offset().top,
			distance = (elementOffset - scrollTop);

		var headerHeight = $('#undefined-sticky-wrapper').height();
		var finalDistance = (distance - headerHeight);

		if (finalDistance < 0) {
			$('#menu-center').addClass('fixed');
			$('#menu-center').css('top', headerHeight);
		} else {
			$('#menu-center').removeClass('fixed');
		}
	});
}

// Contact form first input hover
if (top.location.pathname == '/get-started-today.html' || top.location.pathname == '/get-started-today') { // Get started page only
	$(document).ready(() => {
		setTimeout(() => { $('#name').focus(); }, 500);
		$('#footer-why-banner').css('display', 'none');
	});
}

// Unmatched Revenue
if (top.location.pathname == '/unmatched-revenue.html' || top.location.pathname == '/unmatched-revenue') { // Unmatched revenue page only
	$(document).ready(() => {
		$('#footer-why-banner').css('background-color', '#f9fafd');
	});
}

// OWL Testimonial
$(document).ready(() => {
	$('#owl-testimonial').owlCarousel({
		dots: true,
		dotsEach: true,
		center: true,
		items: 1,
		loop: true,
		margin: 10,
		nav: true,
		navText: false,
		singleItem: true,
		slideSpeed: 1000,
		paginationSpeed: 2000,
		rewindSpeed: 2000,
		touchDrag: true,
		mouseDrag: true
	});
});

// Menu active class
$(document).ready(() => {
	var currentPage = (top.location.pathname).replace('.html', '').replace('/', '');
	if (currentPage == '' || currentPage == 'index') { currentPage = 'home'; }
	currentPage = `#menu-${currentPage}`;
	$(currentPage).addClass('active');
});

// Home page header
var homeHeader = () => {
	var currentPage = (top.location.pathname).replace('.html', '').replace('/', '');
	if (currentPage == '' || currentPage == 'index') {
		if ($(window).width() < 768) {
			$('.home-header-section .content').css('height', $(window).height() - 150);
		} else {
			$('.home-header-section .content').css('height', 'auto');
		}
	}
};

// On load
$(document).ready(() => { homeHeader(); });

// On resize
$(window).resize(() => { homeHeader(); });


// Replace all SVG images with inline SVG
jQuery('img.svg').each(() => {
	var $img = jQuery(this);
	var imgID = $img.attr('id');
	var imgClass = $img.attr('class');
	var imgURL = $img.attr('src');

	jQuery.get(imgURL, (data) => {
		var $svg = jQuery(data).find('svg');
		if (typeof imgID !== 'undefined') {
			$svg = $svg.attr('id', imgID);
		}
		if (typeof imgClass !== 'undefined') {
			$svg = $svg.attr('class', `${imgClass} replaced-svg`);
		}
		$svg = $svg.removeAttr('xmlns:a');
		$img.replaceWith($svg);
	}, 'xml');
});
