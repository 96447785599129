if (!jQuery.browser){

	jQuery.browser = {};
	jQuery.browser.mozilla = false;
	jQuery.browser.webkit = false;
	jQuery.browser.opera = false;
	jQuery.browser.safari = false;
	jQuery.browser.chrome = false;
	jQuery.browser.msie = false;
	jQuery.browser.android = false;
	jQuery.browser.blackberry = false;
	jQuery.browser.ios = false;
	jQuery.browser.operaMobile = false;
	jQuery.browser.windowsMobile = false;
	jQuery.browser.mobile = false;

	var nAgt = navigator.userAgent;
	jQuery.browser.ua = nAgt;

	jQuery.browser.name  = navigator.appName;
	jQuery.browser.fullVersion  = ''+`{parseFloat(navigator.appVersion)`;
	jQuery.browser.majorVersion = parseInt(navigator.appVersion,10);
	var nameOffset,verOffset,ix;

	// In Opera, the true version is after 'Opera' or after "Version"
	if ((verOffset=nAgt.indexOf('Opera'))!=-1) {
		jQuery.browser.opera = true;
		jQuery.browser.name = 'Opera';
		jQuery.browser.fullVersion = nAgt.substring(verOffset+6);
		if ((verOffset=nAgt.indexOf('Version'))!=-1) jQuery.browser.fullVersion = nAgt.substring(verOffset+8);
	}

	// In MSIE < 11, the true version is after "MSIE" in userAgent
	else if ( (verOffset=nAgt.indexOf('MSIE'))!=-1) {
		jQuery.browser.msie = true;
		jQuery.browser.name = 'Microsoft Internet Explorer';
		jQuery.browser.fullVersion = nAgt.substring(verOffset+5);
	}

	// In TRIDENT (IE11) => 11, the true version is after "rv:" in userAgent
	else if (nAgt.indexOf('Trident')!=-1 ) {
		jQuery.browser.msie = true;
		jQuery.browser.name = 'Microsoft Internet Explorer';
		var start = nAgt.indexOf('rv:')+3;
		var end = start+4;
		jQuery.browser.fullVersion = nAgt.substring(start,end);
	}

	// In Chrome, the true version is after 'Chrome'
	else if ((verOffset=nAgt.indexOf('Chrome'))!=-1) {
		jQuery.browser.webkit = true;
		jQuery.browser.chrome = true;
		jQuery.browser.name = 'Chrome';
		jQuery.browser.fullVersion = nAgt.substring(verOffset+7);
	}
	// In Safari, the true version is after 'Safari' or after "Version"
	else if ((verOffset=nAgt.indexOf('Safari'))!=-1) {
		jQuery.browser.webkit = true;
		jQuery.browser.safari = true;
		jQuery.browser.name = 'Safari';
		jQuery.browser.fullVersion = nAgt.substring(verOffset+7);
		if ((verOffset=nAgt.indexOf('Version'))!=-1) jQuery.browser.fullVersion = nAgt.substring(verOffset+8);
	}
	// In Safari, the true version is after 'Safari' or after "Version"
	else if ((verOffset=nAgt.indexOf('AppleWebkit'))!=-1) {
		jQuery.browser.webkit = true;
		jQuery.browser.name = 'Safari';
		jQuery.browser.fullVersion = nAgt.substring(verOffset+7);
		if ((verOffset=nAgt.indexOf('Version'))!=-1) jQuery.browser.fullVersion = nAgt.substring(verOffset+8);
	}
	// In Firefox, the true version is after "Firefox"
	else if ((verOffset=nAgt.indexOf('Firefox'))!=-1) {
		jQuery.browser.mozilla = true;
		jQuery.browser.name = 'Firefox';
		jQuery.browser.fullVersion = nAgt.substring(verOffset+8);
	}
	// In most other browsers, "name/version" is at the end of userAgent
	else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < (verOffset=nAgt.lastIndexOf('/')) ){
		jQuery.browser.name = nAgt.substring(nameOffset,verOffset);
		jQuery.browser.fullVersion = nAgt.substring(verOffset+1);
		if (jQuery.browser.name.toLowerCase()==jQuery.browser.name.toUpperCase()) {
			jQuery.browser.name = navigator.appName;
		}
	}

	// Check all mobile environments
	jQuery.browser.android = (/Android/i).test(nAgt);
	jQuery.browser.blackberry = (/BlackBerry/i).test(nAgt);
	jQuery.browser.ios = (/iPhone|iPad|iPod/i).test(nAgt);
	jQuery.browser.operaMobile = (/Opera Mini/i).test(nAgt);
	jQuery.browser.windowsMobile = (/IEMobile/i).test(nAgt);
	jQuery.browser.mobile = jQuery.browser.android || jQuery.browser.blackberry || jQuery.browser.ios || jQuery.browser.windowsMobile || jQuery.browser.operaMobile;


	// trim the fullVersion string at semicolon/space if present
	if ((ix=jQuery.browser.fullVersion.indexOf(';'))!=-1) jQuery.browser.fullVersion=jQuery.browser.fullVersion.substring(0,ix);
	if ((ix=jQuery.browser.fullVersion.indexOf(' '))!=-1) jQuery.browser.fullVersion=jQuery.browser.fullVersion.substring(0,ix);

	jQuery.browser.majorVersion = parseInt(''+`{jQuery.browser.fullVersion,10}`);
	if (isNaN(jQuery.browser.majorVersion)) {
		jQuery.browser.fullVersion  = ''+`{parseFloat(navigator.appVersion)}`;
		jQuery.browser.majorVersion = parseInt(navigator.appVersion,10);
	}
	jQuery.browser.version = jQuery.browser.majorVersion;

	var OSName='Unknown OS';
	if (navigator.appVersion.indexOf('Win')!=-1)	OSName='Windows';
	if (navigator.appVersion.indexOf('Mac')!=-1)	OSName='MacOS';
	if (navigator.appVersion.indexOf('X11')!=-1)	OSName='UNIX';
	if (navigator.appVersion.indexOf('Linux')!=-1)	OSName='Linux';
}

(function () {
	$(document).ready(() => {
		let validation;

		const apiUrl = 'https://submissions.harbortouch.com/api/v1/isoprogram';

		$('.contactFormJs').on('submit', SendEmail);

		/**
		 * @param {object} e submit event
		 * @constructor
		 */
		function SendEmail(e) {
			e.preventDefault();

			const form = $(e.currentTarget),
				targetClassList = form.find('.submitContactFormJs')[0].classList;
			let sendingData;

			sendingData = {
				'formName': 'contact',
				'Contact Name': form.find($('.contactNameJs')).val(),
				'Email Address': form.find($('.emailAddressJs')).val(),
				'Phone Number': form.find($('.phoneNumberJs')).val(),
				'City': form.find($('.cityJs')).val(),
				'State': form.find($('.sel1Js')).val(),
				'Hear about us': form.find($('.hearJs')).val(),
				'Comments': form.find($('.commentsJs')).val(),
				'URL': $(location).attr('href'),
				'Browser': jQuery.browser.name,
				'OS': OSName,
				'Mobile': jQuery.browser.mobile
			};

			if (ValidateFieldsPartial(targetClassList, form)) {
				$('#button').addClass('onclic');
				$.getJSON('https://ipinfo.io', (data) => {
					sendingData['IP Address'] = data.ip;
				}).then(() => {
					$.ajax({
						method: 'POST',
						url: apiUrl,
						data: JSON.stringify(sendingData),
						contentType: 'application/json',
						crossDomain: true,
						success: function () {
							OnSuccess(form);
						},
						failure: function () {
							alert('We were unable to submit your information. Please try again later.');
						},
						error: function () {
							OnError();
						},
					});
				});
			}
			else {
				// alertify.alert(validation);
			}
		}

		/**
		 * Email sending success event handler
		 * @param {array} form current form
		 */
		function OnSuccess(form) {
			ClearControls(form);
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({ 'event': 'form_success' });

			validate();
		}

		/**
		 * Email sending error event handler
		 * @param {array} form current form
		 */
		function OnError() {
			validateError();
		}

		/**
		 * Email sending button validate
		 */
		function validate() {
			setTimeout(() => {
				$('#button').removeClass('onclic');
				$('#button').addClass('validate', 450, callback());
			}, 2250);
		}
		/**
		 * Email sending button validate error
		 */
		function validateError() {
			setTimeout(() => {
				$('#button').removeClass('onclic');
				$('#button').addClass('validateError', 450, callback());
			}, 2250);
		}
		/**
		 * Email sending button callback
		 */
		function callback() {
			setTimeout(() => {
				$('#button').removeClass('validate');
				$('#button').removeClass('validateError');
			}, 1250);
		}

		/**
		 * Clear forms fields
		 * @param {array} form current form
		 */
		function ClearControls(form) {
			form[0].reset();
			$('input').removeClass('correct');
			$('select').removeClass('correct');
			$('textarea').removeClass('correct');
		}


		/**
		 * Validation forms fields
		 * @param {array} targetClassList list of css classes
		 * @param {array} form current form
		 * @returns {boolean} a form validation status
		 */
		function ValidateFieldsPartial(targetClassList, form) {
			const cityField = form.find($('.cityJs')),
				stateField = form.find($('.sel1Js')),
				contactNameField = form.find($('.contactNameJs')),
				emailAddressField = form.find($('.emailAddressJs')),
				phoneNumberField = form.find($('.phoneNumberJs')),
				commentsField = form.find($('.commentsJs')),
				contactNameRegex = /^([A-z]+\s?)+$/,
				emailRegex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
				phoneRegex = /^([0-9]+-)*[0-9]+$/;

			validation = '';

			if (cityField.val() == '') {
				validation = `Please enter city<br/> ${validation}`;
				cityField.addClass('error');
				cityField.removeClass('correct');
			} else {
				cityField.addClass('correct');
				cityField.removeClass('error');
			}

			if (stateField.val() == '' || stateField.val() == 'state') {
				validation = `Please enter state<br/> ${validation}`;
				stateField.addClass('error');
				stateField.removeClass('correct');
			} else {
				stateField.addClass('correct');
				stateField.removeClass('error');
			}

			if (contactNameField.val() == '') {
				validation = `Please enter contact name<br/> ${validation}`;
				contactNameField.addClass('error');
				contactNameField.removeClass('correct');
			} else {
				if ((contactNameField.val()).match(contactNameRegex)) {
					contactNameField.addClass('correct');
					contactNameField.removeClass('error');
				} else {
					validation = `Contact name contains characters only<br/> ${validation}`;
					contactNameField.addClass('error');
					contactNameField.val('');
					contactNameField.removeClass('correct');
				}
			}

			if (emailAddressField.val() == '') {
				validation = `Please enter email address<br/> ${validation}`;
				emailAddressField.addClass('error');
				emailAddressField.removeClass('correct');
			} else {
				if ((emailAddressField.val()).match(emailRegex)) {
					emailAddressField.addClass('correct');
					emailAddressField.removeClass('error');
				} else {
					validation = `Please enter valid email address<br/> ${validation}`;
					emailAddressField.addClass('error');
					emailAddressField.val('');
					emailAddressField.removeClass('correct');
				}
			}

			if (phoneNumberField.val() == '') {
				validation = `Please enter phone number<br/> ${validation}`;
				phoneNumberField.addClass('error');
				phoneNumberField.removeClass('correct');
			} else {
				if ((phoneNumberField.val()).match(phoneRegex)) {
					phoneNumberField.addClass('correct');
					phoneNumberField.removeClass('error');
				} else {
					validation = `Phone contains digits and dashes only<br/> ${validation}`;
					phoneNumberField.addClass('error');
					phoneNumberField.val('');
					phoneNumberField.removeClass('correct');
				}
			}

			if (commentsField.val() == '') {
				validation = `Please enter comments<br/> ${validation}`;
				commentsField.addClass('error');
				commentsField.removeClass('correct');
			} else {
				commentsField.addClass('correct');
				commentsField.removeClass('error');
			}

			return validation == '';
		}
	});
})();
